/* eslint-disable no-undef */

import React, { Component } from "react"
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import { ThemeProvider } from "theme-ui"
import loadable from "loadable-components"

import theme from "./stylesheets/theme"
import "./App.scss"

const Settings = loadable(() => import("./pages/Settings/Settings"))
const Layout = loadable(() => import("./pages/Layout/Layout"))
const SlackAuth = loadable(() => import("./components/SlackAuth/SlackAuth"))
class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Settings />} />
            </Route>
            <Route path="/slack_auth" element={<SlackAuth />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    )
  }
}

export default App
