import React from "react"
import { hydrate, render } from "react-dom"
import { loadComponents } from "loadable-components"

import reportWebVitals from "./reportWebVitals"
import App from "./App"

const rootElement = document.getElementById("root")

loadComponents()
  .then(() => hydrate(<App />, rootElement))
  .catch(() => render(<App />, rootElement))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
